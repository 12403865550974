export const LinkCss = (cssUrl) => {
   // return true;
    /* eslint-disable no-unreachable */
    const cssLinks = document.getElementsByTagName('link');
    console.log('our css links', cssLinks);
    for (const linkItem of cssLinks) {
        document.querySelectorAll('style,link[rel="stylesheet"]').forEach((element) => {
            if (element.href !== undefined) {
                console.log("element.src", element.href.replace(window.location.origin, ""), cssUrl);
                 if (element.href.replace(window.location.origin, "") !== ("/wp-content/plugins/react-plugin/views" + cssUrl)) {
                    element.remove();
                 }
                 // if (element.href.replace(window.location.origin, "") !== (cssUrl)) {
                 //     element.remove();
                 // }
            }
        });
    }
};
