import * as React from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Box, Container } from "@material-ui/core";
import { ServeyStepOne } from "./serveySteps/ServeyStepOne";
import { ServeyStepTwo } from "./serveySteps/ServeyStepTwo";
import { ServeyStepThree } from "./serveySteps/ServeyStepThree";
import { ServeyStepFour } from "./serveySteps/ServeyStepFour";
import { ServeyStepFive } from "./serveySteps/ServeyStepFive";
import { Get, Post, Serialize } from "../services/http";
import { LinkCss } from "../services/cssService";

export const Lead = () => {
  const [cffResult, setCffResult] = React.useState("");
  const [score, setScore] = React.useState("");
  const { id } = useParams();
  const [serveyCount, setServeyCount] = React.useState(0);
  const [optimaiPopup, setOptimaiPopup] = React.useState(null);
  const [zohoLeadData, setZohoLeadData] = React.useState({});
  const [formObject, setFormObject] = React.useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    mortgage: "",
    terms: "",
  });
  const [validPopup, setValidPopup] = React.useState(false);
  const [upperCalc, setUpperCalc] = React.useState(null);
  const [routh, setRouth] = React.useState(null);
  const [madadStability, setMadadStability] = React.useState(null);
  const [totalRoutes, setTotalRoutes] = React.useState(null);
  const [validationCode, setValidationCode] = React.useState(0);
  const [leadId, setLeadId] = React.useState("");
  const [serveyObject, setServeyObject] = React.useState("");

  React.useEffect(() => {
    LinkCss("/css/style.css");
  }, []);

  const calcPMT = (apr, term, loan) => {
    const terms = term * 12;
    const aprs = apr / 1200;
    const amount = (aprs * -loan * (1 + aprs) ** terms) / (1 - (1 + aprs) ** terms);
    return Math.round(amount);
  };

  const restoreServeyObject = () => {
    const restoredServeyObjectString = localStorage.getItem("serveyObject");
    const serveyObjectReady = JSON.parse(restoredServeyObjectString);
    setServeyObject(serveyObjectReady);
  };

  const getServeyResult = async () => {
    // let objectToSend = { name: 'vasily', action: '' };
    let newServeyObject = {
      ...{
        action: "get_cff_result_by_id",
      },
    };
    let form_data = new FormData();
    form_data.append("action", "get_cff_result_by_id");
    const paramsObj = { action: "get_cff_result_by_id", proposal_id: id.toString() };
    const params = Serialize(paramsObj);
    
    axios.get("https://optimai.co.il/wp-admin/admin-ajax.php?" + params).then(function (response) {
      try {
        console.log("result ", response.data.data.id);
        setCffResult(response.data);
        setServeyCount(parseInt(response.data.data.id) + 250000);
        const scoreRes = response.data.result.ratings * 1.64 - 82;
      } catch (error) { const errorObj = error; }
    });
  };

  const flipString = (value) => {
    let output = [];

    if (value.length === 1) {
      return value;
    }

    for (let i = value.length; i >= 0; i--) {
      output.push(value[i]);
    }

    return output.join("");
  };

  const mask = (num) => {
    let output = [];

    num = num.toString();

    for (let i = 0; i < num.length; i++) {
      if (i === 0 && num[0] === "0") {
        output.push("");
      } else {
        output.push(num[i]);
      }
    }

    num = output.join("");
    output = [];
    let value = flipString(num);

    for (let i = 0; i < value.length; i++) {
      if (i !== 0 && i % 3 === 0) {
        output.push(",");
      }

      if (i !== value.length) {
        output.push(value[i]);
      }
    }

    value = output.join("");

    return flipString(value);
  };

  const removeCommons = (value) => {
    let output = [];

    for (let i = 0; i < value.length; i++) {
      if (value[i] === "," || !(value[i] >= "0" && value[i] <= "9")) {
        output.push("");
      } else {
        output.push(value[i]);
      }
    }

    return output.join("");
  };

  function validateEmail($email) {
    let emailReg = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    if (emailReg.test($email) && $email !== "") {
      return true;
    }
    return false;
  }

  /* const validetePhone = (value) => {
		if (value === undefined) {
			return false;
		} else if (value.length !== 10) {
			return false;
		} else {
			return true;
		}
	}; */

  function validetePhone($phone) {
    let phoneReg = /^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/;
    if (phoneReg.test($phone) && $phone !== "") {
      return true;
    }
    return false;
  }

  const valideteEmpty = (value) => {
    if (value === undefined) {
      return false;
    } else if (value.length < 2) {
      return false;
    } else {
      return true;
    }
  };

  async function updateImutForLead() {
    const params = Serialize({ leadId: leadId, otpDone: "done", action: "updateZohoLeadOTPStatus" });

    await axios
      .get("https://optimai.co.il/wp-admin/admin-ajax.php?" + params)
      .then(function (response) {
        console.log("otpUpdateResult", response);
      }); /* powerlink.co.il */
  }
  async function SendZohoLead() {
    const zohoLeadDataObj = JSON.parse(zohoLeadData);
    zohoLeadDataObj.action = "send_zoho_lead"; // #publish sendTestLead
    // zohoLeadDataObj.action = "sendTestLead";
    const zohoLeadParams = Serialize(zohoLeadDataObj);
    await axios
      .get("https://optimai.co.il/wp-admin/admin-ajax.php?" + zohoLeadParams)
      .then(function (response) {
        console.log("zoho lead response", response);
      }); /* powerlink.co.il */
  }
  async function goToResults() {
    window.location.href = "/results/" + cffResult.result.proposal_id;
  }

  async function phoneValid() {
    const element = document.getElementById("phoneValidInput");
    const elementWrap = element.closest(".popup-input-wrapper");
    elementWrap.classList.remove("active");
    const val = element.value;

    console.log("debugNum", val, parseInt(validationCode));

    if (parseInt(val) === parseInt(validationCode)) {
       await SendZohoLead(); // #publish
       
      try {
        await updateImutForLead();
        await goToResults(); // #publish
      } catch (error) {
        console.log("error", error);
      }
    } else {
      elementWrap.classList.add("active");
    }
  }

  const phoneValidInput = () => {
    const element = document.getElementById("phoneValidInput");
    const elementWrap = element.closest(".popup-input-wrapper");
    elementWrap.classList.remove("active");
  };

  const readInputValue = (identificator) => {
    const formObjectSave = {};
    const element = document.getElementById(identificator);
    const elementWrap = element.closest(".form-input-wrapper");
    const inputValue = element.value;
    formObjectSave[identificator] = inputValue;
    let newFormObject = {
      ...formObject,
      ...formObjectSave,
    };
    setFormObject(newFormObject);

    if (!valideteEmpty(inputValue)) {
      elementWrap.classList.add("notValid");
    } else {
      elementWrap.classList.remove("notValid");
    }

    if (identificator === "email") {
      if (!validateEmail(inputValue) && valideteEmpty(inputValue)) {
        elementWrap.classList.add("notValid");
        elementWrap.classList.add("currValid");
      } else if (validateEmail(inputValue)) {
        elementWrap.classList.remove("notValid");
        elementWrap.classList.remove("currValid");
      }
    }

    if (identificator === "phone") {
      if (!validetePhone(inputValue) && valideteEmpty(inputValue)) {
        elementWrap.classList.add("notValid");
        elementWrap.classList.add("currValid");
      } else if (validetePhone(inputValue)) {
        elementWrap.classList.remove("notValid");
        elementWrap.classList.remove("currValid");
      }
    }
  };

  const radioChange = (event) => {
    const element = document.querySelectorAll(".form-radio-wrapper");
    element[0].classList.remove("notValid");

    let value;

    if (event.target.defaultValue === "need") {
      value = 1;
    } else {
      value = 2;
    }

    let newFormObject = {
      ...formObject,
      ...{
        mortgage: value,
      },
    };
    setFormObject(newFormObject);
  };

  const checkboxChange = (event) => {
    const element = document.querySelectorAll(".form-checkbox-wrapper");
    element[0].classList.remove("notValid");

    let newFormObject = {
      ...formObject,
      ...{
        terms: event.target.checked,
      },
    };
    setFormObject(newFormObject);
  };

  const sendSuccessful = () => {
    const popup = document.querySelectorAll(".form-send-successful");
    popup[0].classList.add("active");

    setTimeout(() => {
      popup[0].classList.remove("active");
    }, 4000);
  };

  const sendForm = () => {
    const inputs = document.querySelectorAll(".form-input-wrapper input");

    for (const input of inputs) {
      const elementWrap = input.closest(".form-input-wrapper");

      if (!valideteEmpty(input.value)) {
        elementWrap.classList.add("notValid");
      }
    }

    if (formObject["mortgage"] === "") {
      const element = document.querySelectorAll(".form-radio-wrapper");
      element[0].classList.add("notValid");
    }

    if (formObject["terms"] === false || formObject["terms"] === "") {
      const element = document.querySelectorAll(".form-checkbox-wrapper");
      element[0].classList.add("notValid");
    }

    if (
      valideteEmpty(formObject["firstName"]) &&
      valideteEmpty(formObject["lastName"]) &&
      valideteEmpty(formObject["phone"]) &&
      valideteEmpty(formObject["email"]) &&
      validateEmail(formObject["email"]) &&
      formObject["terms"] === true &&
      formObject["mortgage"] !== ""
    ) {
      setValidPopup(true);
      const sendObject = {
        ...cffResult.data,
        ...formObject,
        encodedCity: serveyObject["city"],
        action: "sendTestLead",
      };
      const params = Serialize(sendObject);
      setZohoLeadData(JSON.stringify(sendObject));
      console.log("zoho sendObject", sendObject);
      /* axios
        .post(
          "https://flow.zoho.com/871002333/flow/webhook/incoming?zapikey=1001.8b0e0a0735e2885aa384e49bd53944d9.da9afd3a77a44f2813d2747d987bb2df&isdebug=true",
          paramsJson, // Send params as the request body
          {
            headers: {
              "Content-Type": "application/json", // Ensure it's sent as JSON
            },
          }
        )
        .then(function (response) {
          console.log("zoho sent", response);
        })
        .catch(function (error) {
          console.error("zoho error", error);
        });  */

      axios
        .get("https://optimai.co.il/wp-admin/admin-ajax.php?" + params)
        .then(function (response) {    
          // const response = JSON.parse(responseRaw);
          const leadIdValue = response.data?.zoho_optimai_result?.lead_Result?.data[0]?.details.id;
          const otp = response.data?.otp;
          console.log("sendResult", response, "otp--", otp, "leadValue", leadIdValue);
          setLeadId(leadIdValue);
          setValidationCode(otp);
        /*   if (response.data.result3 !== undefined) {
            console.log("sendResult result3", response.data?.result3?.data?.Record?.pcfsystemfield234);
            // console.log("lead id response.data.result2.data.Record.customobject1001id", response.data.result2.data.Record.customobject1001id);
            setValidationCode(response.data?.result3?.data?.Record?.pcfsystemfield234);
            setLeadId(response.data?.result2?.data?.Record?.customobject1001id);
          } else {
             console.log(
              "sendResult result2",
              response.data.result2.data.Record.pcfsystemfield87,
              response.data.result2.data.Record.customobject1001id
            ); 
            setValidationCode(response.data?.result2?.data?.Record?.pcfsystemfield87);
            if (response.data?.result2) { // existing customer (I'm not sure which case)
              setLeadId(response.data?.result2?.data?.Record?.customobject1001id);
            }
            if (response.data?.result3) { // new customer
              setLeadId(response.data?.result3?.data?.Record?.customobject1007id);
            }
          } */

          /* for (const input of inputs) {
					input.value = '';
				} */
          // sendSuccessful();
        }); /* powerlink.co.il */
    }
  };

  const closeValidPopup = () => {
    setValidPopup(false);
    let element = document.getElementById("phoneValidInput");
    element.value = "";
  };

  React.useEffect(() => {
    getServeyResult();
    restoreServeyObject();
  }, []);

  (function (d) {
    let s = d.createElement("script");
    s.setAttribute("data-account", "es8YPEWAQK");
    s.setAttribute("src", "https://cdn.userway.org/widget.js");
    (d.body || d.head).appendChild(s);
  })(document);

  return (
    <>
      <Helmet>
        <title>מחשבון משכנתא חכם - אופטימאי</title>
      </Helmet>

      <noscript>
        Please ensure Javascript is enabled for purposes of{" "}
        <a href="https://userway.org">website accessibility</a>
      </noscript>
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9988069504628480"
        crossorigin="anonymous"
      ></script>
      <body class="loaded">
        <div
          id="call-us-popup-modal-container"
          className={optimaiPopup ? "optimai" : "optimai none"}
        >
          <div id="call-us-modal-wrapper" class="call-us-modal-wrapper">
            <div class="contact-us-popup-wrapper">
              <div class="close-contact-us-popup" onClick={() => setOptimaiPopup(false)}>
                <div class="close-popup-orange-wrapper">
                  <div class="close-popup-white-wrapper">
                    <img src="/img/custom-close-popup-icon.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="contact-us-popup-image-wrapper inline-block">
                <img src="/img/poup-phone-image.png" alt="" />
              </div>
              <div class="contact-us-popup-info-wrapper inline-block">
                <div class="contact-us-popup-ornage-bubble-container">
                  <img src="/img/orange-bubble.png" alt="" />
                  <div class="contact-us-popup-main-title">אני אופטימאי</div>
                  <div class="contact-us-popup-secondary-title">מחשבון משכנתא חכם</div>
                </div>
                <div class="contact-us-popup-text">
                  בעוד כמה רגעים אגלה לך
                  <br />
                  כל מה שאתה צריך לדעת
                  <br />
                  על המשכנתא שלך.
                </div>
                <div class="contact-us-popup-blue-button">
                  משהו לא ברור
                  <br />
                  התקשרו <a href="tel:035052400">03-5052400</a>
                </div>
              </div>
              <div class="popup-button" onClick={() => setOptimaiPopup(false)}>
                הבנתי!
              </div>
            </div>
          </div>
        </div>

        <div className={validPopup ? "popup-wrapper" : "popup-wrapper none"}>
          <div class="popup">
            <div class="popup-close" onClick={() => closeValidPopup()}></div>
            <div class="popup-title">הכנס קוד אמות שנשלח לך בSMS</div>
            <div class="popup-input-wrapper inline-block">
              <div class="popup-input-warning">הקוד לא נכון!</div>
              <input
                class="popup-input number"
                onChange={() => phoneValidInput()}
                id="phoneValidInput"
                type="number"
                inputmode="numeric"
              />
            </div>
            <div class="popup-button-basic inline-block" onClick={() => phoneValid()}>
              אימות
            </div>
          </div>
        </div>

        {/* BEGIN main wrapper */}
        <div class="main-wrapper">
          {/* BEGIN HEADER */}
          <div class="header-wrapper">
            <header class="header">
              <div class="container header__container">
                <div class="header__logo">
                  <a class=" logo">
                    <img src="/img/logo2.png" alt="" onClick={() => setOptimaiPopup(true)} />
                  </a>
                  <a href="tel:035052400" class="header-phone">
                    03-5052400
                    <img src="/img/phone-icon.png" alt="" />
                  </a>
                </div>
                <div class="header__icons h-icons">
                  <div class="h-icons__item">
                    <div class="h-icons__img">
                      <img src="/img/homeicon1.png" alt="" />
                    </div>
                    <div class="h-icons__title">חישוב מספר</div>
                    <div class="h-icons__subtitle">{mask(serveyCount)}</div>
                  </div>
                  <div class="h-icons__item">
                    <div class="h-icons__img">
                      <img src="/img/homeicon2.png" alt="" />
                    </div>
                    <div class="h-icons__title">משכנתא בסך</div>
                    <div class="h-icons__subtitle">
                      {cffResult ? mask(cffResult?.data?.mortgageAmount) : ""}
                    </div>
                  </div>
                  <div class="h-icons__item">
                    <div class="h-icons__img">
                      <img src="/img/homeicon3.png" alt="" />
                    </div>
                    <div class="h-icons__title">לנכס בשווי</div>
                    <div class="h-icons__subtitle">
                      {cffResult ? mask(cffResult?.data?.propertyValue) : ""}
                    </div>
                  </div>
                </div>
                <div class="header__edit">
                  <a href="/" class="edit-button">
                    <img src="/img/edit.png" alt="" />
                    <span>
                      ערוך <br />
                      נתונים
                    </span>
                  </a>
                </div>
              </div>
            </header>
          </div>

          {/* HEADER EOF   */}

          {/* BEGIN MAIN */}
          <main class="main">
            <section class="form-section lemon section">
              <div class="form-slider-wrapper lemon">
                <div class="form-slider">
                  <div class="form-slider-text">
                    המשכנתא שחישבת<span>בדף הבא</span>
                  </div>
                  <input
                    type="range"
                    min="2"
                    max="60"
                    id="calc-mashkanta"
                    class="form-slider-input"
                    disabled
                  />
                </div>
              </div>
              <div class="form-wrapper lemon">
                {/* <div class="form-send-successful">
                  <span>
                    פרטיך נשלחו בהצלחה
                    <br />
                    אנחנו נחזור אליך
                  </span>
                </div> */}

                <div class="right lemon">
                  <div class="form-send-successful">
                    <span>
                      פרטיך נשלחו בהצלחה
                      <br />
                      אנחנו נחזור אליך
                    </span>
                  </div>
                  <div>
                    <img class="inline-block" src="/img/lemon-logo-desktop.png" alt="" />
                  </div>
                  <div class="form-title">
                    <strong>רוצה שנוציא לבנקים את המיץ בשבילך?</strong>
                    אנחנו בלימון ייעוץ משכנתאות מגישים עשרות תיקים בחודש אשר מאפשרים לנו לקבל ריביות
                    נמוכות וכוח מיקוח חזק מול הבנקים.
                  </div>
                  <div class="form-inputs-wrapper lemon">
                    <div class="form-input-wrapper inline-block lemon">
                      <div class="from-input-warning">חובה לסמן!</div>
                      <input
                        onChange={() => readInputValue("firstName")}
                        type="text"
                        placeholder="שם פרטי"
                        id="firstName"
                      />
                    </div>
                    <div class="form-input-wrapper inline-block lemon">
                      <div class="from-input-warning">חובה לסמן!</div>
                      <input
                        onChange={() => readInputValue("lastName")}
                        type="text"
                        placeholder="שם משפחה"
                        id="lastName"
                      />
                    </div>
                    <div class="form-input-wrapper inline-block lemon">
                      <div class="from-input-warning">חובה לסמן!</div>
                      <div class="from-input-warning currValid">הזן מספר טלפון חוקי!</div>
                      <input
                        onChange={() => readInputValue("phone")}
                        type="text"
                        placeholder="טלפון נייד"
                        id="phone"
                        inputmode="numeric"
                      />
                    </div>
                    <div class="form-input-wrapper inline-block lemon">
                      <div class="from-input-warning">חובה לסמן!</div>
                      <div class="from-input-warning currValid">הזן כתובת אמייל חוקית!</div>
                      <input
                        onChange={() => readInputValue("email")}
                        type="text"
                        placeholder="דואר אלקטרוני"
                        id="email"
                      />
                    </div>
                  </div>
                  <div class="form-questions-wrapper lemon">
                    <div class="form-questions-item inline-block">
                      <div class="form-tip">
                        {/* <img class="inline-block" src="/img/tip-icon.png" alt="" /> */}
                        <div class="form-tip-text inline-block">
                          מעוניין/ת שנציג מטעם לימון משכנתאות יחזור אליך?
                          {/*      <span>
                            טיפ: אם בכוונתך לקחת משכנתא ב 24 החודשים הקרובים, בחר "צריך משכנתא"
                          </span> */}
                        </div>
                      </div>
                    </div>
                    <div class="form-questions-item inline-block send">
                      <div
                        class="form-radio-wrapper inline-block"
                        style={{ borderBottom: "1px solid #0173bd14" }}
                      >
                        <div class="form-checkbox-wrapper">
                          <div class="from-input-warning">חובה לסמן!</div>
                          <input
                            class="inline-block"
                            onChange={(event) => radioChange(event)}
                            type="radio"
                            name="mortgage"
                            value="need"
                            id="mortagageNeed"
                          />
                          <label class="inline-block" for="need">
                            רוצה להוציא לבנקים את המיץ
                          </label>
                        </div>
                        <div class="form-checkbox-wrapper">
                          <input
                            class="inline-block"
                            onChange={(event) => radioChange(event)}
                            type="radio"
                            name="mortgage"
                            value="interested"
                            id="mortagageInterested"
                          />
                          <label class="inline-block" for="interested">
                            לא
                          </label>
                        </div>
                      </div>
                      <br />
                      <div class="form-checkbox-wrapper">
                        <div class="from-input-warning">חובה לסמן!</div>
                        <input
                          class="inline-block"
                          type="checkbox"
                          value="need"
                          onChange={(event) => checkboxChange(event)}
                        />
                        <label class="inline-block" for="need">
                          אני מאשר את תנאי השימוש באתר.
                        </label>
                        <br />
                      </div>
                    </div>
                    <a
                      class="form-send-button inline-block lemon no-before"
                      id="form-send-button"
                      onClick={() => sendForm()}
                    >
                      שלח
                    </a>
                  </div>
                </div>
                <div class="left lemon"></div>
              </div>
            </section>
          </main>
          {/* MAIN EOF */}

          {/* BEGIN FOOTER */}
          <footer class="footer">
            <div class="container">
              <p>
                כל הזכויות שמורות לאופטימאי בע"מ | הגלישה באתר מהווה הסכמה
                <br />
                <strong>
                  <a href="/regulations" target="_blank">
                    תקנון האתר
                  </a>
                </strong>{" "}
                | &nbsp;
                <strong>
                  <a href="/terms" target="_blank">
                    תנאי ביטול עסקה
                  </a>
                </strong>{" "}
                | &nbsp;
                <strong>
                  <a href="/accessibility" target="_blank">
                    הצהרת נגישות
                  </a>
                </strong>
              </p>
            </div>
          </footer>
          {/* FOOTER EOF */}
        </div>
        {/* main wrapper EOF */}
      </body>
    </>
  );
};
